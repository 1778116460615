(function () {
  document.addEventListener('DOMContentLoaded', function () {
    // Intersection Observer effects
    const doc = document.querySelector('html');
    const hasIO = typeof IntersectionObserver === 'function';
    if (hasIO) {
      doc.classList.add("IntersectionObserver");

      const ioBasic = [...document.querySelectorAll('[data-io-animate]')];

      if (ioBasic.length > 0) {
        ioBasic.forEach(function (animEl, index) {
          const threshold = animEl.getAttribute('data-io-threshold') || 0.5;

          const ioBasicOptions = {
            rootMargin: '0px',
            threshold: threshold,
          };

          const ioBasicCallback = function (entries) {
            entries.forEach(function (entry) {
              const {target} = entry;
              const ir = entry.intersectionRatio;

              if (ir >= threshold) {
                target.setAttribute('data-io-animate', 'after');
              }
              else {
                // target.setAttribute('data-io-animate', 'active')
              }
            })
          };

          const ioBasicObserver = new IntersectionObserver(ioBasicCallback, ioBasicOptions);
          ioBasicObserver.observe(animEl);
        });
      }
    }
  });
})();

/**
 * responsive-tables.js
 */
(function ($) {
  $(document).ready(function () {
    if ($("table").length) {
      $("table").not(".calendar-calendar table").each(function () {
        var $tableWidth = $(this).css("width");

        if (!$(this).hasClass("tablesaw") && !$(this).hasClass("no-tablesaw")) {
          if ($(this).has('thead').length) {
            $(this).addClass('tablesaw tablesaw-stack js-processed').attr('data-tablesaw-mode', 'stack');

            $(this).css({
              'width': '100%',
              'max-width': $tableWidth
            });
          }
        }
      });

      // Activate Tablesaw.
      $(document).trigger("enhance.tablesaw");
    }
  });
})(jQuery);

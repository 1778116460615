/**
 * events.js
 */
(function($) {
  $(document).ready(function(){
    /**
     * Event Calendar view
     */
    if ($('.view-event-calendar')) {
      $('.view-event-calendar').once('oberlin-event-calendar', function() {
        var curDay = obieFns.getUrlParameter('date[value][day]');

        if (curDay) {
          $('.month a').filter(function() {
            return $(this).text() === curDay;
          }).parents('td.mini').addClass('mini__selected-day');
        }
      });
    }
  });
})(jQuery);

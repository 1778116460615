/**
 * blogs.js
 */
(function($) {
  Drupal.behaviors.oberlinBlogsBehavior = {
    attach: function(context, settings) {
      // Disable Drupal default viewsScrollTop action; our real target is
      // different from the view iteself, because the filters push the views
      // content down on the page on mobile.
      //
      // From there, only scroll if the filter title is visible; assumption is
      // it's only visible after a filter is selected and not on initial page
      // load.
      //
      // We also need something added after the initial page load, so we skip
      // the first round of ajaxStops, and only execute scroll after that's done
      // (otherwise we get a scroll on page load, or, the pager doesn't work).
      //
      // TODO: figure out non-ajax URL targets for filtered listings.
      if ($('.view-id-blog_article_listing').length && Drupal.ajax) {
        Drupal.ajax.prototype.commands.viewsScrollTop = null;

        $("body").once('ajaxStop', function() {
          $(document).ajaxStop(function() {
            if ($("#blog-article-listing-filter-title:visible").length || $("body").hasClass("ajaxStop--ready")) {
              var $crumbHeight = $(".breadcrumb-container").outerHeight() + 60;

              $('html, body').animate({
                scrollTop: $crumbHeight
              }, 800);
            } else {
              $("body").addClass("ajaxStop--ready");
            }
          });
        });
      }


      // Blog listing
      if ($('.view-blog-article-listing').length) {
        var bodyInput = $('.form-item-body input').val();
        var blogTag = $('.form-item-blog-tag select').val();

        $('.view-all-blog-link').click(function(e) {
          e.preventDefault();
          $("#edit-blog-tag-all a").click();
        });

        if (bodyInput === '' && blogTag === 'All') {
          // Show the featured blog article
          $('.blogger__post--featured').show();

          // Clear and hide the filter text
          $('.blog-article-listing-filter-text').html('');
          $('#blog-article-listing-filtered-by-block').hide();

          // // Hide the clear filters link
          // $('.blog-article-listing-clear-link').hide();
        } else {
          // Hide the featured blog article
          $('.blogger__post--featured').hide();

          // Update the filter text to include the search parameters
          var blogTagText = $('.form-item-blog-tag select option:selected').text();
          var searchText = bodyInput;

          if (bodyInput !== '' && blogTag !== 'All') {
            searchText += ', ' + blogTagText;
          } else if (blogTag != 'All') {
            searchText = blogTagText;
          }

          $('.blog-article-listing-filter-text').html(searchText);
          $('#blog-article-listing-filtered-by-block').show();

          // // Show the clear filters link
          // $('.blog-article-listing-clear-link').show();
        }
      }

      $('.blog__filters .views-widget-filter-field_blog_tags_tid label').text('Reveal filters');

      $('.blog__filters .views-widget-filter-field_blog_tags_tid label').once('oberlin', function() {
        $(this).click(function() {
          var text = $(this).text() == 'Reveal filters' ? 'Hide filters' : 'Reveal filters';
          $(this).text(text);
          $(this).toggleClass('engaged');
          $(this).siblings('.views-widget').toggleClass('engaged');
        });
      });

      // Exposed blog filters
      if ($('.bef-select-as-links').length) {
        $('.bef-select-as-links .children').hide();

        // Show the selected blog tag's child set
        $('.exposed-blog-tag.selected').parent('.children').show();

        $('.exposed-blog-tag.parent a').once('oberlin-tags', function() {
          $(this).off('click');
        });

        $('.exposed-blog-tag.parent').once('oberlin-tags', function() {
          $(this).click(function(e) {
            e.preventDefault();

            var target = $(this).data("target");
            $('[data-children="' + target + '"]').slideToggle();
          });
        });
      }
    }
  };
})(jQuery);

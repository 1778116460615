/**
 * hero-video.js
 */
(function ($) {
  $(document).ready(function () {
    /**
     * Hero video control(s)
     */
    if ($('.hero-with-video').length) {
      // Grab our elements
      var $heroVideo = $('#heroVideo').get(0);
      var $heroVideoIcon = $('#heroVideoControlButtonIcon');
      var $heroVideoToggle = $('#heroVideoControlButton');
      var $heroVideoToggleSpan = $('#heroVideoControlButton .invisible');

      // Play/Pause Functions

      var playHeroVideo = function () {
        $heroVideo.play();
        $heroVideoIcon.removeClass('fa-play').addClass('fa-pause');
        $heroVideoToggleSpan.text('Pause hero video');
        document.cookie = "pauseHomepageVideo=false; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=" + curPath;
      };

      var pauseHeroVideo = function () {
        $heroVideo.pause();
        $heroVideoIcon.removeClass('fa-pause').addClass('fa-play');
        $heroVideoToggleSpan.text('Play hero video');
        document.cookie = "pauseHomepageVideo=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=" + curPath;
      };

      // Cookie
      var curPath = location.pathname;
      var videoPauseCookie = document.cookie.replace(/(?:(?:^|.*;\s*)pauseHomepageVideo\s*\=\s*([^;]*).*$)|^.*$/, "$1");

      if (videoPauseCookie === "true") {
        pauseHeroVideo();
      }

      $heroVideoToggle.click(function (e) {
        if ($heroVideo.paused) {
          playHeroVideo();
        }
        else {
          pauseHeroVideo();
        }
      });
    }
  });
})(jQuery);

(function () {
  document.addEventListener('DOMContentLoaded', function () {
    const viewMoreListing = document.querySelectorAll('[data-listing-view-more]');
    if (viewMoreListing.length > 0) {
      viewMoreListing.forEach(function (listing) {
        const itemsToKeep = listing.dataset.listingViewMore;
        const listingItemsCount = listing.children.length;

        if (listingItemsCount > itemsToKeep) {
          for (let i = itemsToKeep; i < listingItemsCount; i++) {
            listing.children[i].dataset.hidden = "true";
          }

          // TODO: could make this a function
          const viewMoreButton = document.createElement("button");
          const viewMoreButtonText = document.createTextNode("View more");
          viewMoreButton.appendChild(viewMoreButtonText);
          viewMoreButton.setAttribute('id', 'listingViewMoreButton')

          const buttonContainer = document.createElement("div");
          buttonContainer.classList.add("centerer");
          buttonContainer.appendChild(viewMoreButton);

          listing.appendChild(buttonContainer);

          viewMoreButton.addEventListener('click', function () {
            listing.querySelectorAll('[data-hidden]').forEach(function (hiddenItem) {
              hiddenItem.dataset.hidden = 'false';
            });

            listing.removeChild(buttonContainer);
          });
        }
      });
    }
  });
})();

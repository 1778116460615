/**
 * oberlin-ajax.js
 */
(function($) {
  /**
   * Override AJAX behaviors.
   */
  if (Drupal.ajax) {
    /**
     * Improved ajax spinner.
     */
    Drupal.ajax.prototype.beforeSend = function (xmlhttprequest, options) {
      if (this.form) {
        options.extraData = options.extraData || {};
        options.extraData.ajax_iframe_upload = '1';

        var v = $.fieldValue(this.element);
        if (v !== null) {
          options.extraData[this.element.name] = Drupal.checkPlain(v);
        }
      }

      $(this.element).addClass('progress-disabled').attr('disabled', true);

      // Insert progressbar or throbber.
      if (this.progress.type == 'bar') {
        var progressBar = new Drupal.progressBar('ajax-progress-' + this.element.id, eval(this.progress.update_callback), this.progress.method, eval(this.progress.error_callback));
        if (this.progress.message) {
          progressBar.setProgress(-1, this.progress.message);
        }
        if (this.progress.url) {
          progressBar.startMonitoring(this.progress.url, this.progress.interval || 1500);
        }
        this.progress.element = $(progressBar.element).addClass('ajax-progress ajax-progress-bar');
        this.progress.object = progressBar;
        $(this.element).after(this.progress.element);
      }
      else if (this.progress.type == 'throbber') {
        this.progress.element = $('<div class="ajax-progress ajax-progress-throbber"><span class="fa fa-spinner fa-pulse"></span></div>');
        if (this.progress.message) {
          $('.throbber', this.progress.element).after('<div class="message">' + this.progress.message + '</div>');
        }
        $('body').after(this.progress.element);
      }
    };

    /**
     * Monkey patching an animation onto the AJAX Success event.
     */
    Drupal.ajax.prototype.success = function (response, status) {
      // Remove the progress element. Now with animation!
      if (this.progress.element) {
        $(this.progress.element).animate({
          opacity: 0,
        }, {
          duration: 1000,
          easing: 'easeInOutQuart',
          complete: function() {
            $(this).remove();
          },
        });
      }
      if (this.progress.object) {
        this.progress.object.stopMonitoring();
      }
      $(this.element).removeClass('progress-disabled').removeAttr('disabled');

      Drupal.freezeHeight();

      for (var i in response) {
        if (response.hasOwnProperty(i) && response[i]['command'] && this.commands[response[i]['command']]) {
          this.commands[response[i]['command']](this, response[i], status);
        }
      }

      // Reattach behaviors, if they were detached in beforeSerialize(). The
      // attachBehaviors() called on the new content from processing the
      // response commands is not sufficient, because behaviors from the entire
      // form need to be reattached.
      if (this.form) {
        var settings = this.settings || Drupal.settings;
        Drupal.attachBehaviors(this.form, settings);
      }

      Drupal.unfreezeHeight();

      // Remove any response-specific settings so they don't get used on the
      // next call by mistake.
      this.settings = null;
    };

    /**
     * Monkey patching easing into the viewsScrollTop animation.
     */
    Drupal.ajax.prototype.commands.viewsScrollTop = function (ajax, response, status) {
      // Scroll to the top of the view. This will allow users
      // to browse newly loaded content after e.g. clicking a pager
      // link.
      var offset = $(response.selector).offset();
      // We can't guarantee that the scrollable object should be
      // the body, as the view could be embedded in something
      // more complex such as a modal popup. Recurse up the DOM
      // and scroll the first element that has a non-zero top.
      var scrollTarget = response.selector;
      while ($(scrollTarget).scrollTop() == 0 && $(scrollTarget).parent()) {
        scrollTarget = $(scrollTarget).parent();
      }
      // Only scroll upward.
      if (offset.top - 10 < $(scrollTarget).scrollTop()) {
        $(scrollTarget).animate({
          scrollTop: (offset.top - 10),
        }, {
          duration: 1000,
          easing: 'easeInOutQuart',
        });
      }
    };
  }
})(jQuery);

/**
 * responsive-videos.js
*/
(function ($) {
  $(document).ready(function () {
    if ($("iframe").length) {
      // Scope the wrapper to video embeds.
      $("iframe.video_overlay_iframe").each(function () {
        $(this).wrap('<div class="iframe-video-container js-processed cool"></div>');
      });
    }
  });
})(jQuery);

(function () {
  document.addEventListener('DOMContentLoaded', function () {
    // Trigger animations after the hero image is loaded
    const heroPanel = document.querySelector('.hero');

    let loadingImage = new Image();
    loadingImage.addEventListener("load", function () {
      heroPanel.setAttribute('data-hero-image-loaded', '');
    });

    const heroImageSrc = document.querySelector('.hero__image img');
    if (heroImageSrc) {
      loadingImage.src = heroImageSrc.src;
    }
  });
})();

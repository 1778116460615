/**
 * Global menu panel
 */
(function ($) {
  document.addEventListener('DOMContentLoaded', function () {
    const $globalMenuButton = $('#globalMenuButton');
    const $globalMenuPanel = $('#globalMenuPanel');

    // Keyboard handler for escaping out of the menu
    const keypressHandler = function (e) {
      if (e.keyCode === 27) {
        $globalMenuButton.click();
      }
    }

    // Need the width when still displayed; otherwise 0s out
    // TODO: update this on window resize? confirm still needed?
    const globalMenuPanelWidth = $globalMenuPanel.width();

    // Initialize panel display
    $globalMenuPanel.css('opacity', '0');
    $globalMenuPanel.css('right', globalMenuPanelWidth * -1);
    $globalMenuPanel.hide();

    // Global Menu Button click handler
    $globalMenuButton.click(function () {
      // De-activate escape key handler, if present
      document.removeEventListener("keyup", keypressHandler);

      // Toggle active classes and handle animation in or out based on that.
      $globalMenuButton.toggleClass('active');
      $globalMenuPanel.toggleClass('is-active');

      if ($globalMenuPanel.hasClass('is-active')) {
        $globalMenuPanel.show().animate({
          opacity: 1,
          right: 0
        });

        document.addEventListener("keyup", keypressHandler);
      }
      else {
        $globalMenuPanel.animate({
          opacity: 0,
          right: globalMenuPanelWidth * -1
        }, function () {
          $globalMenuPanel.hide();
        });
      }

      // Underlay and no-scroll handling.
      // Using non-jQuery code as there seems to be no immediate need to modify.
      // Two underlays because the menu panel overlays the breadcrumb bar inside
      // the header and the main overlay won't cover that (z-index).
      // TODO: probably can be more elegant if I break the menu panel out.
      if (document.querySelector('#globalUnderlay')) {
        document.querySelector('#globalUnderlay').classList.add('exit');

        window.setTimeout(function () {
          document.querySelector('#globalUnderlay').remove();
        }, 500);

        document.querySelector("body").classList.toggle('no-scroll');
      }
      else {
        let template = document.querySelector('#globalUnderlayTemplate');
        let holder = document.querySelector('#site-header');
        let underlay = document.importNode(template.content, true);

        holder.appendChild(underlay);

        document.querySelector("body").classList.toggle('no-scroll');

        // Click to exit
        $("#globalUnderlay").click(function() {
          $globalMenuButton.click();
        })
      }
    })

    // Main menu links - slide toggling
    const $mainMenuMainLinks = $('.main-menu .menu > li.expanded > a');
    $mainMenuMainLinks.each(function () {
      $(this).siblings('ul').slideToggle();

      $(this).click(function (e) {
        e.preventDefault();
        $(this).toggleClass('has-ul-active');
        $(this).siblings('ul').slideToggle();
      })
    })
  });
})(jQuery);

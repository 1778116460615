(function () {
  document.addEventListener('DOMContentLoaded', function () {
    // using a class here because jquery modals script changes the button id
    // ¯\_(ツ)_/¯
    const viewMoreEventsDates = document.querySelector('.view-more-events-button');
    if (viewMoreEventsDates) {
      const visibleDates = document.querySelector('.event-dates-links');
      const allDates = visibleDates.cloneNode(true);

      for (let i = 3; i < visibleDates.children.length; i++) {
        visibleDates.children[i].classList.add('hidden');
      }

      const allDatesContainer = document.createElement("div");
      allDatesContainer.classList.add('hidden');
      allDatesContainer.setAttribute('id', 'allEventDates');

      allDatesContainer.appendChild(allDates);
      document.querySelector('body').appendChild(allDatesContainer);

      // from here we let jquery modals handle the button,
      // which is not represented in the pattern library
    }
  });
})();
